<template>
  <c-box
    style="background-color: white"
    borderRadius="5px"
    p="5"
    w="210px"
    mr="17px"
    mb="17px"
    @click="goToProfile"
  >
    <c-flex>
      <c-image
        v-if="type === 'followers'"
        style="border-radius: 8px"
        h="50px"
        w="50px"
        mb="3"
        alt="profile picture"
        :src="
          user.follower.profilePhoto
            ? user.follower.profilePhoto
            : require('@/assets/img/user.jpg')
        "
      />
      <c-image
          v-else
          style="border-radius: 8px"
          h="50px"
          w="50px"
          mb="3"
          alt="profile picture"
        :src="
          user.following.profilePhoto
            ? user.following.profilePhoto
            : require('@/assets/img/user.jpg')
        "
      />
      <c-flex
        v-if="type === 'followers'"
        ml="3"
        flexDirection="column"
        align="start"
      >
        <c-text class="company_name" fontSize="14px" fontWeight="700"
        >{{ user.follower.firstname }} {{ user.follower.lastname }}</c-text
        >
        <c-text
          v-if="user.follower.companies.length > 0"
          class="company_name"
          fontSize="13px"
          fontWeight="600"
        >
          {{ user.follower.companies[0].name }}
        </c-text>
        <!-- <c-text fontSize="13px" mb="3" fontWeight="400">{{
          connection.role
        }}</c-text> -->
      </c-flex>

      <c-flex v-else ml="3" flexDirection="column" align="start">
        <c-text class="company_name" fontSize="14px" fontWeight="700"
          >{{ user.following.firstname }} {{ user.following.lastname }}</c-text
        >
        <c-text v-if="user.following.companies.length > 0" class="company_name" fontSize="13px" fontWeight="600">{{
          user.following.companies[0].name
        }}</c-text>
        <!-- <c-text fontSize="13px" mb="3" fontWeight="400">{{
          connection.role
        }}</c-text> -->
      </c-flex>
    </c-flex>
    <c-flex>
      <c-button
        v-if="type === 'followers'"
        variant-color="vc-orange"
        variant="outline"
        :disabled="currentUser.id === user.follower.id"
        size="sm"
        @click="sendQuickMessage(user.follower)"
        >Message</c-button
      >
      <c-button
        v-else
        variant-color="vc-orange"
        variant="outline"
        :disabled="currentUser.id === user.following.id"
        size="sm"
        @click="sendQuickMessage(user.following)"
        >Message</c-button
      >
      <c-button
        v-if="type === 'followers'"
        ml="5"
        variant-color="vc-orange"
        :disabled="currentUser.id === user.follower.id"
        size="sm"
        @click="follow"
        >
        {{
          followed || followingUser ? 'Unfollow' : 'Follow'
        }}
      </c-button
      >
    </c-flex>
  </c-box>
</template>

<script>
import messagingMixin from '@/mixins/messaging.js';
import {followUser, unFollowUser} from "@/services/user";
import {mapGetters} from "vuex";

export default {
  name: 'FollowerCard',
  data(){
    return{
      followed: false,
      usersFollowers: null
    }
  },
  computed: {
    followingUser(){
      return this.currentUser.user_followers.some((flw) => flw.following.id === this.user.follower.id)
    },
    ...mapGetters({
      currentUser: "auth/getUser"
    })
  },
  created() {
  },
  props: ['user', 'type'],
  mixins: [messagingMixin],
  methods: {
    goToProfile() {
      this.$router.push({
        name: 'Profile',
        params: {
          userId: this.user.follower
            ? this.user.follower.id
            : this.user.following.id,
        },
      });
    },
    async follow() {
      this.btnLoading = true;
      if(this.followed || this.followingUser){
        try {
          await unFollowUser(this.user.follower.id, this.currentUser.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = false;
        }catch (e){
          this.btnLoading = false
        }
      }
      else{
        try {
          await followUser(this.user.follower.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = true;
        } catch (e) {
          this.btnLoading = false;
          console.log(e);
        }
      }
    },
  }
};
</script>

<style scoped>
.company_name{
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  height: 18px;
}
button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
