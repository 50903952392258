<template>
  <c-box
    style="background-color: white"
    borderRadius="5px"
    mr="3"
    p="5"
    w="210px"
    mb="30px"
    @click="goToProfile"
  >
    <c-flex>
      <c-image
        style="border-radius: 8px"
        h="50px"
        w="50px"
        mb="3"
        alt="profile picture"
        :src="
          user.profilePhoto
            ? user.profilePhoto
            : require('@/assets/img/user.jpg')
        "
      />
      <c-flex ml="3" flexDirection="column" align="start">
        <c-text class="company_name" fontSize="14px" fontWeight="700"
          >{{ user.firstname }} {{ user.lastname }}</c-text
        >
        <c-text
          v-if="user.companies.length > 0"
          class="company_name"
          fontSize="13px"
          fontWeight="600"
          >{{ user.companies[0].name }}</c-text
        >
        <!-- <c-text fontSize="13px" mb="3" fontWeight="400">{{
          connection.role
        }}</c-text> -->
      </c-flex>
    </c-flex>
    <c-flex>
      <c-button
        variant-color="vc-orange"
        variant="outline"
        size="sm"
        :disabled="currentUser.id === user.id"
        @click="sendQuickMessage(user)"
        >Message</c-button
      >
      <c-button
        ml="5"
        variant-color="vc-orange"
        :disabled="currentUser.id === user.id"
        size="sm"
        @click="follow"
        >Follow</c-button
      >
    </c-flex>
  </c-box>
</template>

<script>
import messagingMixin from '@/mixins/messaging.js';
import { mapGetters } from 'vuex';
import { followUser, unFollowUser } from '@/services/user';

export default {
  name: 'CompanyFollowerCard',
  props: ['user'],
  mixins: [messagingMixin],
  data() {
    return {
      btnLoading: false,
      followed: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
    }),
    followingUser() {
      return this.userData.user_followers.some(
        (flu) => flu.follower.id === this.user.id
      );
    },
  },
  methods: {
    goToProfile(){
      this.$router.push({
        name: 'Profile',
        params: {
          userId: this.user.id,
        },
      });
    },
    async follow() {
      this.btnLoading = true;
      if (this.followed || this.followingUser) {
        try {
          await unFollowUser(this.userData.id, this.user.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = false;
        } catch (e) {
          this.btnLoading = false;
        }
      } else {
        try {
          await followUser(this.user.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = true;
        } catch (e) {
          this.btnLoading = false;
          console.log(e);
        }
      }
    },
  },
};
</script>

<style scoped>
.company_name {
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  height: 18px;
}
button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
