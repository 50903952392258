<template>
  <c-box mt="8" py="4" px="4" boxShadow="1px 1px 4px rgba(75, 102, 171, 0.20)">
    <c-text fontSize=".8rem" fontWeight="bold">Team</c-text>
    <c-box>
      <c-box v-if="!members.length">
        <c-text>No member added yet</c-text>
      </c-box>
      <c-flex flexWrap="wrap" style="margin: 0" v-else>
        <c-box
          d="flex"
          flex-direction="column"
          align-items="center"
          v-for="(member, i) in members"
          :key="i"
          mr="3"
          mb="3"
          @click="goToProfile(member.user)"
        >
          <c-avatar
            cursor="pointer"
            size="lg"
            :name="member.user.firstname + ' ' + member.user.lastname"
            :src="member.user.profilePhoto"
            bg="#ef5323"
            borderWidth="0px"
          />
          <c-text mt="2" fontSize=".8rem" textAlign="center" minH="1.3rem"
            >{{ member.user.firstname }} {{ member.user.lastname }}</c-text
          >
          <c-text fontSize=".6rem" textAlign="center">{{ member.role }}</c-text>
        </c-box>
      </c-flex>
    </c-box>
  </c-box>
</template>

<script>
export default {
  methods: {
    goToProfile(user) {
      this.$router.push({
        name: 'Profile',
        params: {
          userId: user.id,
        },
      });
    },
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
